<template>
  <div>
    <v-select
      v-if="readonly"
      :value="weather"
      :items="weatherKinds"
      label="天気"
      :loading="isLoading"
      disabled
      append-icon=""
      :menu-props="{ auto: true, offsetY: true }"
      readonly
      hide-details
      class="pa-0 ma-0"
    />

    <v-select
      v-else
      v-model="weather"
      :items="weatherKinds"
      label="天気"
      :loading="isSubmitting || isLoading"
      :disabled="isLoading || isSubmitting"
      :menu-props="{ auto: true, offsetY: true }"
      hide-details
      class="pa-0 ma-0"
      @change="saveDailyWeather"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import DailyWeatherApi from '@/api/admin/DailyWeather'
import useCompInit from '@/views/composable/useCompInit'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isSubmitting = ref(false)
    const weather = ref(null)
    const weatherKinds = [
      { value: 'sunny', text: '晴れ' },
      { value: 'cloudy', text: '曇り' },
      { value: 'rainy', text: '雨' },
      { value: 'typhoon', text: '台風' },
    ]

    const getDailyWeather = async () => {
      const res = await DailyWeatherApi.getDailyWeather({ date: props.date })

      if (res?.data) {
        weather.value = res.data.weather
      }
    }

    const saveDailyWeather = async () => {
      if (props.readonly) return

      isSubmitting.value = true
      const res = await DailyWeatherApi.saveDailyWeather({
        date: props.date,
        weather: weather.value,
      })

      if (res?.data) {
        vm.$toast.success('天気を保存しました')
        weather.value = res.data.weather
      } else {
        vm.$toast.error('天気の保存に失敗しました')
      }

      isSubmitting.value = false
    }

    initWith([
      getDailyWeather(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      weather,
      weatherKinds,

      // methods
      saveDailyWeather,
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
</style>
