<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <transition name="slideRight">
          <v-row
            v-if="selected.length > 0"
            style="animation-duration: 0.3s"
          >
            <v-col class="px-4 py-6 d-flex">
              <v-spacer />

              <v-btn
                color="primary"
                :ripple="false"
                @click="save"
              >
                {{ toSaveStatus.text }}
              </v-btn>
            </v-col>
          </v-row>
        </transition>

        <v-data-table
          v-model="selected"
          :headers="[
            { value: 'user.name', text: '対象者' },
            ...headers[role.value],
            { value: 'earningsTotalAmount', text: '支給額' },
          ]"
          :items="items[role.value]"
          :header-props="{ sortByText: 'ソート' }"
          item-key="user.id"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
          no-data-text="評価の結果: 対象者なし"
        >
          <template
            v-for="header in headers[role.value]"
            #[`header.${header.value}`]="{header: headerObj}"
          >
            {{ headerObj.text }}

            <v-chip
              v-if="headerObj.evaluationCycleSpan"
              :key="`header-${header.value}`"
              :color="evaluationCycleSpanColors[headerObj.evaluationCycleSpan]"
              :class="`${evaluationCycleSpanColors[headerObj.evaluationCycleSpan]}--text`"
              small
              class="v-chip-light-bg"
            >
              {{ evaluationCycleSpanTexts[headerObj.evaluationCycleSpan] }}
            </v-chip>
          </template>

          <template
            v-for="header in headers[role.value]"
            #[`item.${header.value}`]="{item}"
          >
            <template v-if="item.earnings.filter(o => +o.paySystemUnitId === +header.paySystemUnitId).length > 0">
              <v-text-field
                v-for="(earning, earningIdx) in item.earnings.filter(o => +o.paySystemUnitId === +header.paySystemUnitId)"
                :key="`user-${item.user.id}-earnings-from-pay-system-unit-${header.paySystemUnitId}-${earningIdx}`"
                v-model.number="earning.amount"
                :loading="!earning.id"
                :hide-details="!!earning.id"
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                min="0"
                prefix="¥"
                class="pa-0 ma-0 my-2"
              />
            </template>

            <template v-else>
              <v-text-field
                :key="`user-arbitrary-earnings-from-pay-system-unit-${header.paySystemUnitId}`"
                hide-details
                class="pa-0 ma-0 my-2"
                @click="addArbitraryEarning(item, header)"
              />
            </template>
          </template>

          <template #[`item.earningsTotalAmount`]="{item}">
            <v-text-field
              :value="sumTotalEarningsAmount(item)"
              readonly
              solo
              flat
              prefix="¥"
              hide-details
              class="pa-0 ma-0 my-2"
            />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  chain,
  flatMap,
  every,
  uniqBy,
} from 'lodash'
import PaySystemApi from '@/api/admin/PaySystem'
import EarningsApi from '@/api/admin/Earnings'
import useCompInit from '@/views/composable/useCompInit'
import useDateFormat from '@/views/composable/useDateFormat'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { initWith, isLoading } = useCompInit()
    const { dateTime } = useDateFormat()
    const currentTabIdx = ref(0)
    const castHeaders = ref([])
    const waiterHeaders = ref([])
    const allianceHeaders = ref([])
    const castEarningsItems = ref([])
    const waiterEarningsItems = ref([])
    const allianceEarningsItems = ref([])
    const selected = ref([])
    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
      { text: 'アライアンス', value: 'alliance' },
    ]

    const items = computed(() => {
      return {
        cast: castEarningsItems.value,
        waiter: waiterEarningsItems.value,
        alliance: allianceEarningsItems.value,
      }
    })

    const headers = computed(() => {
      return {
        cast: uniqBy(castHeaders.value, 'text'),
        waiter: uniqBy(waiterHeaders.value),
        alliance: uniqBy(allianceHeaders.value, 'text'),
      }
    })

    const toSaveStatus = computed(() => {
      if (selected.value.length <= 0) return { value: 'disabled', text: '保存できません' }

      if (every(selected.value, o => every(o.earnings, e => !!e.id))) return { value: 'updateOnly', text: '更新' }
      if (every(selected.value, o => every(o.earnings, e => !e.id))) return { value: 'createOnly', text: '保存' }

      return { value: 'save', text: '追加/更新' }
    })

    const sumTotalEarningsAmount = item => {
      // eslint-disable-next-line newline-per-chained-call
      return chain(item.earnings).map('amount').compact().sumBy(o => +o).value()
    }

    const initEarnings = async () => {
      // NOTE: 現状、日次のインターバルでしかクロージングさせないのでintervalは0
      const res = await PaySystemApi.initEarnings({ date: props.date, interval: 0 })

      if (res?.data) {
        castEarningsItems.value = [...res.data.castItems]
        waiterEarningsItems.value = [...res.data.waiterItems]
        allianceEarningsItems.value = [...res.data.allianceItems]

        castHeaders.value = [...res.data.castHeaders]
        waiterHeaders.value = [...res.data.waiterHeaders]
        allianceHeaders.value = [...res.data.allianceHeaders]
      }
    }

    const saveAllEarnings = async earnings => {
      const res = await EarningsApi.saveAll({ earnings })

      if (res?.data) {
        vm.$toast.success(`バックを${toSaveStatus.value.text}しました`)

        // NOTE: すべてのレコードが更新処理でない限り初期化してidを取得する
        if (!(toSaveStatus.value.value === 'updateOnly')) emit('reload')
      }

      selected.value = []
    }

    const save = () => {
      const earningsToSave = flatMap(selected.value, o => o.earnings)
      if (earningsToSave.length > 0) saveAllEarnings(earningsToSave)
    }

    const addArbitraryEarning = (item, header) => {
      const newEarning = {
        userId: item.user.id,
        amount: 0,
        fixedAt: props.fixedAt,
        text: `${header.text} 任意入力`,
        paySystemUnitId: header.paySystemUnitId,
      }

      const targetItems = [...items.value[roles[currentTabIdx.value].value]]
      targetItems.find(o => +o.user.id === +item.user.id)?.earnings?.push(newEarning)

      items.value[roles[currentTabIdx.value].value] = targetItems
    }

    const timeoutId = setTimeout(() => {
      if (isLoading.value) emit('loading', true)
    }, 3000)
    initWith(
      [
        initEarnings(),
      ],
    ).then(() => {
      emit('loading', false)
      clearTimeout(timeoutId)
    })

    return {
      // data
      isLoading,
      currentTabIdx,
      castHeaders,
      waiterHeaders,
      allianceHeaders,
      castEarningsItems,
      waiterEarningsItems,
      allianceEarningsItems,
      selected,
      roles,
      evaluationCycleSpanColors: {
        daily: 'accent',
        weekly: 'success',
        half_monthly: 'warning',
        monthly: 'secondary',
      },
      evaluationCycleSpanTexts: {
        daily: '日ごと',
        weekly: '週ごと',
        half_monthly: '半期ごと',
        monthly: '月ごと',
      },

      // computed
      items,
      headers,
      toSaveStatus,

      // methods
      dateTime,
      sumTotalEarningsAmount,
      save,
      addArbitraryEarning,
    }
  },
}
</script>
