var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-tabs',{staticStyle:{"background":"transparent"},attrs:{"show-arrows":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:("tab[" + (role.value) + "]")},[_vm._v(" "+_vm._s(role.text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab-item',{key:("tabItems[" + (role.value) + "]")},[_c('transition',{attrs:{"name":"slideRight"}},[(_vm.selected.length > 0)?_c('v-row',{staticStyle:{"animation-duration":"0.3s"}},[_c('v-col',{staticClass:"px-4 py-6 d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","ripple":false},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.toSaveStatus.text)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.earningsFromWageHeaders,"items":_vm.earningsFromWageItems[role.value],"header-props":{ sortByText: 'ソート' },"item-key":"user.id","hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":"","no-data-text":"評価の結果: 対象者なし"},scopedSlots:_vm._u([{key:"item.timecard.startAt",fn:function(ref){
var item = ref.item;
return _vm._l((item.timecards),function(timecard,timecardIdx){return _c('v-text-field',{key:("user-" + (item.user.id) + "-timecard-" + (timecard.id) + "-" + timecardIdx + "-start-at"),staticClass:"pa-0 ma-0 my-2",staticStyle:{"width":"64px"},attrs:{"value":timecard.startAt ? _vm.hhmm(timecard.startAt) : null,"hide-details":"","type":"time","error":new Date(timecard.startAt) > new Date(timecard.leaveAt)},on:{"change":function($event){return _vm.changeTime({
              time: $event,
              updateTargetObj: timecard,
              updateTargetKey: 'startAt',
              item: item,
            })}}})})}},{key:"item.timecard.leaveAt",fn:function(ref){
            var item = ref.item;
return _vm._l((item.timecards),function(timecard,timecardIdx){return _c('div',{key:("user-" + (item.user.id) + "-timecard-" + (timecard.id) + "-" + timecardIdx + "-leave-at")},[_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",staticStyle:{"width":"64px"},attrs:{"value":(timecard.startAt && timecard.leaveAt) ? _vm.hhmm(timecard.leaveAt) : null,"disabled":!timecard.startAt,"error":!timecard.leaveAt,"hide-details":"","type":"time"},on:{"change":function($event){return _vm.changeTime({
                time: $event,
                updateTargetObj: timecard,
                updateTargetKey: 'leaveAt',
                item: item,
              })}}})],1)})}},{key:"item.timecard.breakTimecardsAttributes.breakTimeStartAt",fn:function(ref){
              var item = ref.item;
return _vm._l((item.timecards),function(timecard,timecardIdx){return _c('div',{key:("user-" + (item.user.id) + "-timecard-" + (timecard.id) + "-" + timecardIdx + "-break-timecard-start-at")},_vm._l((timecard.breakTimecardsAttributes),function(breakTimecard,breakTimecardIdx){return _c('v-text-field',{key:("user-" + (item.user.id) + "-break-timecard-" + (breakTimecard.id) + "-" + breakTimecardIdx + "-breakTimeStartAt"),staticClass:"pa-0 ma-0 my-2",staticStyle:{"width":"64px"},attrs:{"value":(timecard.startAt && breakTimecard.breakTimeStartAt) ? _vm.hhmm(breakTimecard.breakTimeStartAt) : null,"disabled":!timecard.startAt || !timecard.leaveAt,"hide-details":"","type":"time"},on:{"input":function($event){return _vm.changeTime({
                time: $event,
                updateTargetObj: breakTimecard,
                updateTargetKey: 'breakTimeStartAt',
                item: item,
              })}}})}),1)})}},{key:"item.timecard.breakTimecardsAttributes.breakTimeEndAt",fn:function(ref){
              var item = ref.item;
return _vm._l((item.timecards),function(timecard,timecardIdx){return _c('div',{key:("user-" + (item.user.id) + "-timecard-" + (timecard.id) + "-" + timecardIdx + "-break-timecard-end-at")},_vm._l((timecard.breakTimecardsAttributes),function(breakTimecard,breakTimecardIdx){return _c('v-text-field',{key:("user-" + (item.user.id) + "-break-timecard-" + (breakTimecard.id) + "-" + breakTimecardIdx + "-breakTimeEndAt"),staticClass:"pa-0 ma-0 my-2",staticStyle:{"width":"64px"},attrs:{"value":(timecard.startAt && breakTimecard.breakTimeStartAt) ? _vm.hhmm(breakTimecard.breakTimeEndAt) : null,"disabled":!timecard.startAt || !timecard.leaveAt || !breakTimecard.breakTimeStartAt,"hide-details":"","type":"time"},on:{"input":function($event){return _vm.changeTime({
                time: $event,
                updateTargetObj: breakTimecard,
                updateTargetKey: 'breakTimeEndAt',
                item: item,
              })}}})}),1)})}},{key:"item.workingHours",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcWorkingHours(item.timecards))+" ")]}},{key:"item.earningsFromWage",fn:function(ref){
              var item = ref.item;
return _vm._l((item.earningsFromWage),function(earning,earningIdx){return _c('div',{key:("user-" + (item.user.id) + "-earnings-rom-wage-" + (earning.id) + "-" + earningIdx)},[_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",attrs:{"loading":!earning.id,"messages":!earning.id ? '未保存/システム提案中' : '',"hide-details":!!earning.id,"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","prefix":"¥"},model:{value:(earning.amount),callback:function ($$v) {_vm.$set(earning, "amount", _vm._n($$v))},expression:"earning.amount"}})],1)})}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }