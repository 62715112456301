<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />
  <div v-else>
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <transition name="slideRight">
          <v-row
            v-if="selected.length > 0"
            style="animation-duration: 0.3s"
          >
            <v-col class="px-4 py-6 d-flex">
              <v-spacer />

              <v-btn
                :ripple="false"
                color="primary"
                @click="save"
              >
                {{ toSaveStatus.text }}
              </v-btn>
            </v-col>
          </v-row>
        </transition>

        <!-- eslint-disable vue/no-deprecated-v-bind-sync -->
        <v-data-table
          v-model="selected"
          :expanded="expanded"
          :headers="[
            { value: 'user.name', text: '対象者' },
            { value: 'earningsAmount', text: '総支給額' },
            { value: 'beforehandPayment', text: '日払' },
            { value: 'earningsTotalAmount', text: '最終' },
          ]"
          item-key="user.id"
          :items="items[role.value]"
          :header-props="{ sortByText: 'ソート' }"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
          no-data-text="データがありません"
          show-expand
          @update:expanded="expandRow"
        >
          <template #[`header.beforehandPayment`]="{header}">
            {{ header.text }}
            <v-dialog
              v-model="isCalcBeforehandPaymentAmount"
              width="500"
              persistent
              :retain-focus="false"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  small
                  class="pa-0 ma-0 ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  一括入力
                </v-btn>
              </template>
              <v-card>
                <v-card-title>日払額 計算</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model.number="beforehandPaymentPercent"
                        type="number"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        suffix="%"
                        hide-details
                        class="pa-0 ma-0 ml-4"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model.number="beforehandPaymentLimitAmount"
                        type="number"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        prefix="¥"
                        label="上限額"
                        hide-details
                        class="pa-0 ma-0 ml-4"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="beforehandPaymentPercent <= 0"
                    :ripple="false"
                    color="primary"
                    @click="calcBeforehandPaymentAmount(role.value)"
                  >
                    自動入力
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :ripple="false"
                    text
                    @click="isCalcBeforehandPaymentAmount = false"
                  >
                    キャンセル
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template #[`item.earningsAmount`]="{item}">
            <v-text-field
              :value="sumEarningsAmount(item)"
              readonly
              solo
              flat
              prefix="¥"
              hide-details
              class="pa-0 ma-0 my-2"
            />
          </template>

          <template #[`item.beforehandPayment`]="{item}">
            <v-text-field
              v-for="(paidEarning, paidEarningIdx) in item.paidEarnings"
              :key="`user-${item.user.id}-paid-earnings-${paidEarningIdx}`"
              v-model.number="paidEarning.paidAmount"
              hide-details
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              min="0"
              prefix="¥"
              class="pa-0 ma-0 my-2"
            />
          </template>

          <template #[`item.earningsTotalAmount`]="{item}">
            <v-text-field
              :value="sumTotalEarningsAmount(item)"
              readonly
              solo
              flat
              prefix="¥"
              hide-details
              class="pa-0 ma-0 my-2"
            />
          </template>

          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-treeview :items="item.earnings">
                <template #label="{ item: node }">
                  <v-row>
                    <v-col
                      cols="6"
                      class="white-space-normal"
                    >
                      {{ node.text }}
                    </v-col>
                    <v-col
                      cols="6"
                      align-end
                      class="white-space-normal"
                    >
                      {{ `¥${node.amount.toLocaleString()}` }}
                    </v-col>
                  </v-row>
                </template>

                <template #append="{ item: node }">
                  <v-btn
                    icon
                    fab
                    :ripple="false"
                    @click="deleteEarning(node.id, item)"
                  >
                    <v-icon small>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-treeview>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  chain,
  each,
  update,
  findIndex,
  every,
} from 'lodash'
import { mdiTrashCanOutline } from '@mdi/js'
import EarningsApi from '@/api/admin/Earnings'
import useCompInit from '@/views/composable/useCompInit'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { initWith, isLoading } = useCompInit()
    const currentTabIdx = ref(0)
    const castEarningsItems = ref([])
    const waiterEarningsItems = ref([])
    const allianceEarningsItems = ref([])
    const isCalcBeforehandPaymentAmount = ref(false)
    const beforehandPaymentPercent = ref(0)
    const beforehandPaymentLimitAmount = ref(0)
    const selected = ref([])
    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
      { text: 'アライアンス', value: 'alliance' },
    ]

    const items = computed(() => {
      return {
        cast: castEarningsItems.value,
        waiter: waiterEarningsItems.value,
        alliance: allianceEarningsItems.value,
      }
    })

    const toSaveStatus = computed(() => {
      if (selected.value.length <= 0) return { value: 'disabled', text: '保存できません' }

      if (every(selected.value, o => every(o.paidEarnings, e => !!e.id))) return { value: 'updateOnly', text: '更新' }
      if (every(selected.value, o => every(o.paidEarnings, e => !e.id))) return { value: 'createOnly', text: '保存' }

      return { value: 'save', text: '追加/更新' }
    })

    const sumEarningsAmount = item => {
      // eslint-disable-next-line newline-per-chained-call
      return chain(item.earnings).map('amount').compact().sumBy(o => +o).value()
    }

    const sumPaidEarningsAmount = item => {
      // eslint-disable-next-line newline-per-chained-call
      return chain(item.paidEarnings).map('paidAmount').compact().sumBy(o => +o).value()
    }

    const sumTotalEarningsAmount = item => {
      return sumEarningsAmount(item) - sumPaidEarningsAmount(item)
    }

    const calcBeforehandPaymentAmount = role => {
      if (!(+beforehandPaymentPercent.value > 0 || +beforehandPaymentPercent.value > 0)) return

      const ratio = +beforehandPaymentPercent.value / 100.0

      each(items.value[role], item => {
        const earningsAmount = sumEarningsAmount(item)

        let calculatedPaidAmount = 0

        if (ratio > 0) calculatedPaidAmount = Math.round(earningsAmount * ratio)
        if (+beforehandPaymentLimitAmount.value > 0 && calculatedPaidAmount > +beforehandPaymentLimitAmount.value) calculatedPaidAmount = beforehandPaymentLimitAmount.value

        update(item.paidEarnings[0], 'paidAmount', () => calculatedPaidAmount)
        // NOTE: intervalも0(日次)でしか送らないのでearningsFromWageは基本的には1つしかない想定だが、has_many :earnings
        // 一応他のやつがあったら0円にする処理
        each(item.paidEarnings.slice(1, item.paidEarnings.length), o => update(o, 'paidAmount', 0))
      })

      isCalcBeforehandPaymentAmount.value = false
      beforehandPaymentPercent.value = 0
      beforehandPaymentLimitAmount.value = 0
    }

    const initEarningsItems = data => {
      return chain(data)
        .map(o => {
          return {
            ...o,
            earnings: o.earnings?.length > 0 ? o.earnings : [],
            paidEarnings: o.paidEarnings?.length > 0 ? o.paidEarnings : [{
              id: null,
              paidAmount: 0,
              userId: o.user.id,
              text: `日払い ${props.date}`,
              fixedAt: props.fixedAt,
            }],
          }
        })
        .value()
    }

    const getEarnings = async () => {
      // NOTE: 現状、日次のインターバルでしかクロージングさせないのでintervalは0
      const res = await EarningsApi.getResult({ date: props.date, interval: 0 })

      if (res?.data) {
        castEarningsItems.value = initEarningsItems([...res.data.castItems || []])
        waiterEarningsItems.value = initEarningsItems([...res.data.waiterItems || []])
        allianceEarningsItems.value = initEarningsItems([...res.data.allianceItems || []])
      }
    }

    const deleteEarning = async (deleteTargetEarningId, item) => {
      const res = await EarningsApi.deleteEarning(deleteTargetEarningId)

      if (res?.status === 200) {
        const removeTargetIdx = findIndex(item.earnings, o => +o.id === +deleteTargetEarningId)
        item.earnings.splice(removeTargetIdx, 1)

        vm.$toast.success('削除しました')
      }
    }

    const saveAllPaidEarnings = async earnings => {
      const res = await EarningsApi.saveAll({
        earnings,
        isBeforehandPayment: true,
      })

      if (res?.data) {
        vm.$toast.success(`日払を${toSaveStatus.value.text}しました`)

        // NOTE: すべてのレコードが更新処理でない限り初期化してidを取得する
        if (!(toSaveStatus.value.value === 'updateOnly')) emit('reload')
      }

      selected.value = []
    }

    const save = () => {
      const earningsToSave = chain(selected.value)
        .flatMap(o => o.paidEarnings)
        .reject(e => !Number(e.paidAmount) && !e.id) // NOTE: 新規作成で０円の日払いのやつは無視する
        .value()

      if (earningsToSave.length > 0) saveAllPaidEarnings(earningsToSave)
      else selected.value = []
    }

    const timeoutId = setTimeout(() => {
      if (isLoading.value) emit('loading', true)
    }, 3000)
    initWith(
      [
        getEarnings(),
      ],
    ).then(() => {
      emit('loading', false)
      clearTimeout(timeoutId)
    })

    // NOTE: expandedがなぜか3回アクションが発火して開かないときがあるので
    const expanded = ref([])
    let lastExpanded = null
    const expandRow = event => {
      const now = new Date().getTime()

      if (lastExpanded === null || now - lastExpanded > 300) {
        expanded.value = event
        lastExpanded = now
      }
    }

    return {
      // data
      isLoading,
      currentTabIdx,
      castEarningsItems,
      waiterEarningsItems,
      allianceEarningsItems,
      selected,
      roles,
      evaluationCycleSpanColors: {
        daily: 'accent',
        weekly: 'success',
        half_monthly: 'warning',
        monthly: 'secondary',
      },
      evaluationCycleSpanTexts: {
        daily: '日ごと',
        weekly: '週ごと',
        half_monthly: '半期ごと',
        monthly: '月ごと',
      },
      isCalcBeforehandPaymentAmount,
      beforehandPaymentPercent,
      beforehandPaymentLimitAmount,
      expanded,

      // computed
      items,
      calcBeforehandPaymentAmount,
      toSaveStatus,

      // methods
      sumEarningsAmount,
      sumTotalEarningsAmount,
      deleteEarning,
      save,
      expandRow,

      icons: {
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
