var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-tabs',{staticStyle:{"background":"transparent"},attrs:{"show-arrows":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:("tab[" + (role.value) + "]")},[_vm._v(" "+_vm._s(role.text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab-item',{key:("tabItems[" + (role.value) + "]")},[_c('transition',{attrs:{"name":"slideRight"}},[(_vm.selected.length > 0)?_c('v-row',{staticStyle:{"animation-duration":"0.3s"}},[_c('v-col',{staticClass:"px-4 py-6 d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"ripple":false,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.toSaveStatus.text)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"expanded":_vm.expanded,"headers":[
          { value: 'user.name', text: '対象者' },
          { value: 'earningsAmount', text: '総支給額' },
          { value: 'beforehandPayment', text: '日払' },
          { value: 'earningsTotalAmount', text: '最終' } ],"item-key":"user.id","items":_vm.items[role.value],"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":"","no-data-text":"データがありません","show-expand":""},on:{"update:expanded":_vm.expandRow},scopedSlots:_vm._u([{key:"header.beforehandPayment",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-dialog',{attrs:{"width":"500","persistent":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 ma-0 ml-4",attrs:{"ripple":false,"small":""}},'v-btn',attrs,false),on),[_vm._v(" 一括入力 ")])]}}],null,true),model:{value:(_vm.isCalcBeforehandPaymentAmount),callback:function ($$v) {_vm.isCalcBeforehandPaymentAmount=$$v},expression:"isCalcBeforehandPaymentAmount"}},[_c('v-card',[_c('v-card-title',[_vm._v("日払額 計算")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"pa-0 ma-0 ml-4",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","suffix":"%","hide-details":""},model:{value:(_vm.beforehandPaymentPercent),callback:function ($$v) {_vm.beforehandPaymentPercent=_vm._n($$v)},expression:"beforehandPaymentPercent"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"pa-0 ma-0 ml-4",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","prefix":"¥","label":"上限額","hide-details":""},model:{value:(_vm.beforehandPaymentLimitAmount),callback:function ($$v) {_vm.beforehandPaymentLimitAmount=_vm._n($$v)},expression:"beforehandPaymentLimitAmount"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.beforehandPaymentPercent <= 0,"ripple":false,"color":"primary"},on:{"click":function($event){return _vm.calcBeforehandPaymentAmount(role.value)}}},[_vm._v(" 自動入力 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","ripple":false,"text":""},on:{"click":function($event){_vm.isCalcBeforehandPaymentAmount = false}}},[_vm._v(" キャンセル ")])],1)],1)],1)]}},{key:"item.earningsAmount",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",attrs:{"value":_vm.sumEarningsAmount(item),"readonly":"","solo":"","flat":"","prefix":"¥","hide-details":""}})]}},{key:"item.beforehandPayment",fn:function(ref){
        var item = ref.item;
return _vm._l((item.paidEarnings),function(paidEarning,paidEarningIdx){return _c('v-text-field',{key:("user-" + (item.user.id) + "-paid-earnings-" + paidEarningIdx),staticClass:"pa-0 ma-0 my-2",attrs:{"hide-details":"","type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","prefix":"¥"},model:{value:(paidEarning.paidAmount),callback:function ($$v) {_vm.$set(paidEarning, "paidAmount", _vm._n($$v))},expression:"paidEarning.paidAmount"}})})}},{key:"item.earningsTotalAmount",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",attrs:{"value":_vm.sumTotalEarningsAmount(item),"readonly":"","solo":"","flat":"","prefix":"¥","hide-details":""}})]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-treeview',{attrs:{"items":item.earnings},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var node = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"white-space-normal",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(node.text)+" ")]),_c('v-col',{staticClass:"white-space-normal",attrs:{"cols":"6","align-end":""}},[_vm._v(" "+_vm._s(("¥" + (node.amount.toLocaleString())))+" ")])],1)]}},{key:"append",fn:function(ref){
        var node = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","ripple":false},on:{"click":function($event){return _vm.deleteEarning(node.id, item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }