<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div
    v-else
    class="closing-cash-expenses"
  >
    <v-data-table
      :headers="[
        { value: 'primaryLabel', text: '大ラベル', sortable: false },
        { value: 'secondaryLabel', text: '小ラベル', sortable: false },
        { value: 'amount', text: '金額' },
      ]"
      :items="items"
      :header-props="{ sortByText: 'ソート' }"
      class="text-no-wrap"
      hide-default-footer
      disable-pagination
      disable-sort
      show-select
      no-data-text="データがありません"
    >
      <template #[`header.data-table-select`] />
      <template #[`item.data-table-select`] />

      <template #[`item.primaryLabel`]="{item}">
        <v-combobox
          v-model="item.expensePrimaryLabelId"
          :items="primaryLabels"
          item-value="id"
          item-text="name"
          :error="!item.expensePrimaryLabelId"
          single-line
          hide-details
          :return-object="false"
          :menu-props="{ auto: true, offsetY: true }"
          class="pa-0 ma-0 my-2"
        >
          <template #selection="{ item: idOrName }">
            <span v-if="primaryLabels.find(label => +label.id === +idOrName)">
              {{ primaryLabels.find(label => +label.id === +idOrName).name }}
            </span>

            <span v-else>
              {{ idOrName }}
            </span>
          </template>
        </v-combobox>
      </template>

      <template #[`item.secondaryLabel`]="{item}">
        <v-combobox
          v-model="item.expenseSecondaryLabelId"
          :items="secondaryLabels"
          item-value="id"
          item-text="name"
          single-line
          hide-details
          :return-object="false"
          :menu-props="{ auto: true, offsetY: true }"
          class="pa-0 ma-0 my-2"
        >
          <template #selection="{ item: idOrName }">
            <span v-if="secondaryLabels.find(label => +label.id === +idOrName)">
              {{ secondaryLabels.find(label => +label.id === +idOrName).name }}
            </span>

            <span v-else>
              {{ idOrName }}
            </span>
          </template>
        </v-combobox>
      </template>

      <template #[`item.amount`]="{item}">
        <v-text-field
          v-model.number="item.amount"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          min="0"
          prefix="¥"
          hide-details
          class="pa-0 ma-0 my-2"
        />
      </template>
    </v-data-table>

    <v-row class="mt-1 position-relative">
      <v-col cols="6">
        <v-btn
          fab
          :ripple="false"
          color="primary"
          small
          top
          absolute
          left
          @click="items.splice(items.length, 0, initItem(null, {}))"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="toSaveStatus.value !== 'disabled'"
        cols="6"
        class="d-flex"
      >
        <v-spacer />
        <transition name="slideRight">
          <v-btn
            v-if="hasChange"
            color="primary"
            :ripple="false"
            :disabled="!isValid"
            style="animation-duration: 0.3s"
            @click="save"
          >
            {{ toSaveStatus.text }}
          </v-btn>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  map,
  filter,
  every,
  chain,
  isEqual,
  sortBy,
} from 'lodash'
import { mdiPlus } from '@mdi/js'
import ExpenseTagApi from '@/api/waiter/ExpenseTag'
import ExpenseApi from '@/api/admin/Expense'
import useCompInit from '@/views/composable/useCompInit'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    expenseOn: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const cashExpenses = ref([])
    const items = ref([])
    const primaryLabels = ref([])
    const secondaryLabels = ref([])

    const toSaveStatus = computed(() => {
      if (items.value.length <= 0) return { value: 'disabled', text: '保存できません' }

      if (every(items.value, o => !!o.id)) return { value: 'updateOnly', text: '更新' }
      if (every(items.value, o => !o.id)) return { value: 'createOnly', text: '保存' }

      return { value: 'save', text: '追加/更新' }
    })

    const isValid = computed(() => {
      return every(items.value, 'expensePrimaryLabelId')
    })

    const hasChange = computed(() => {
      const originalItemValues = chain(cashExpenses.value)
        .flatMap(expenseData => {
          const {
            amount,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
          } = expenseData.attributes

          return [
            expenseData.id,
            amount,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
          ]
        })
        .uniq()
        .value()

      const currentItemValues = chain(items.value)
        .flatMap(item => {
          const {
            id,
            amount,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
          } = item

          return [
            id || 'new',
            amount,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
          ]
        })
        .uniq()
        .value()

      return !isEqual(sortBy(originalItemValues), sortBy(currentItemValues))
    })

    const initItem = (id = null, attributes = {}) => {
      const {
        amount,
        expensePrimaryLabelId,
        expenseSecondaryLabelId,
      } = attributes

      return {
        id,
        amount: amount || 0,
        expenseOn: props.expenseOn,
        expensePrimaryLabelId,
        expenseSecondaryLabelId,
        withCashRegisterTransaction: true,
      }
    }

    const getExpenses = async () => {
      const res = await ExpenseApi.getExpenses({
        date: props.date,
        interval: 0,
      })

      if (res?.data) {
        cashExpenses.value = filter([...res.data.expenses.data], 'meta.withCashRegisterTransaction')
        items.value = map(cashExpenses.value, expenseData => {
          return initItem(expenseData.id, expenseData.attributes)
        })
      }
    }

    const getExpenseTags = async () => {
      const res = await ExpenseTagApi.getExpenseTags()

      if (res?.data) {
        const { tags } = res.data.expenseTags.data.attributes
        primaryLabels.value = tags.primaryTags
        secondaryLabels.value = tags.secondaryTags
      }
    }

    const save = async () => {
      isLoading.value = true

      const res = await ExpenseApi.saveAll({ expenses: items.value })

      if (res?.data) {
        vm.$toast.success(`出金を${toSaveStatus.value.text}しました`)

        emit('saved')

        cashExpenses.value = filter([...res.data.expenses.data], 'meta.withCashRegisterTransaction')
        items.value = map(cashExpenses.value, expenseData => {
          return initItem(expenseData.id, expenseData.attributes)
        })

        await getExpenseTags()
      }

      isLoading.value = false
    }

    initWith([
      getExpenses(),
      getExpenseTags(),
    ])

    return {
      // data
      isLoading,
      cashExpenses,
      items,
      primaryLabels,
      secondaryLabels,

      // computed
      toSaveStatus,
      isValid,

      // method
      initItem,
      save,
      hasChange,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
.closing-cash-expenses th {
  min-width: 120px;
}
</style>
