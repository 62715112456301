<template>
  <div>
    <v-text-field
      v-if="readonly"
      label="日報記入者"
      :value="dailyReportCloserName"
      :loading="isLoading"
      disabled
      hide-details
      readonly
    />

    <v-text-field
      v-else
      label="日報記入者"
      :value="dailyReportCloserName"
      :loading="isLoading || isSubmitting"
      :disabled="isLoading || isSubmitting"
      hide-details
      readonly
    >
      <template #append-outer>
        <v-btn
          v-if="!dailyReportCloser.id"
          small
          color="primary"
          :loading="isSubmitting || isLoading"
          :disabled="isSubmitting || isLoading"
          @click="saveDailyReportCloser"
        >
          保存
        </v-btn>

        <v-btn
          v-if="dailyReportCloser.id && Number(dailyReportCloser.attributes.userId) !== Number(currentUser.id)"
          small
          color="error"
          :loading="isSubmitting || isLoading"
          :disabled="isSubmitting || isLoading"
          @click="saveDailyReportCloser"
        >
          自分に変更
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import DailyReportCloserApi from '@/api/admin/DailyReportCloser'
import useCompInit from '@/views/composable/useCompInit'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const dailyReportCloser = ref({})
    const { isLoading, initWith } = useCompInit()
    const { currentUser } = useCurrentData()
    const isSubmitting = ref(false)

    const dailyReportCloserName = computed(() => {
      if (isLoading.value) return ''

      if (dailyReportCloser.value.id) return dailyReportCloser.value.meta.userName

      if (props.readonly) return ''

      return currentUser.value.name
    })

    const getDailyReportCloser = async () => {
      const res = await DailyReportCloserApi.getDailyReportCloser({ date: props.date })

      if (res?.data) {
        dailyReportCloser.value = res.data.dailyReportCloser.data
      }
    }

    const saveDailyReportCloser = async () => {
      if (props.readonly) return

      isSubmitting.value = true
      const res = await DailyReportCloserApi.saveDailyReportCloser({ date: props.date })

      if (res?.data) {
        vm.$toast.success('日報記入者を保存しました')

        dailyReportCloser.value = res.data.dailyReportCloser.data
      }

      isSubmitting.value = false
    }

    initWith([
      getDailyReportCloser(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      dailyReportCloser,

      // computed
      dailyReportCloserName,
      saveDailyReportCloser,
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
</style>
