<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-text-field
            :value="moneyProfitAmount"
            prefix="¥"
            hide-details
            readonly
            filled
            label="理論値"
          />
        </v-col>

        <v-col cols="5">
          <v-text-field
            v-model.number="realMoneyProfitAmount"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            min="0"
            prefix="¥"
            :loading="!realMoneyProfitData.id"
            :messages="!realMoneyProfitData.id ? '未保存' : ''"
            :hide-details="!!realMoneyProfitData.id"
            label="実態"
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            :value="realMoneyProfitAmount - moneyProfitAmount"
            prefix="¥"
            hide-details
            readonly
            disabled
            label="差分(不明金)"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <div>
        <v-checkbox
          v-model="withCashRegisterTransaction"
          label="レジ出金と連動"
          :disabled="realMoneyProfitAmount < 0"
          :ripple="false"
          :messages="realMoneyProfitAmount < 0 ? '現金利益(実態)がマイナスの場合はレジ出金と連動できません' : ''"
          class="mt-0 pt-0"
        />
        <v-btn
          color="primary"
          class="w-full"
          :ripple="false"
          @click="save"
        >
          保存
        </v-btn>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from '@vue/composition-api'
import FinanceApi from '@/api/admin/Finance'
import RealMoneyProfitApi from '@/api/admin/RealMoneyProfit'
import useCompInit from '@/views/composable/useCompInit'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const moneyProfitAmount = ref(0)
    const realMoneyProfitAmount = ref(0)
    const realMoneyProfitData = ref({})
    const withCashRegisterTransaction = ref(true)

    const getMoneyProfit = async () => {
      const res = await FinanceApi.getMoneyProfit({
        date: props.date,
        interval: 0,
      })

      if (res?.data) {
        moneyProfitAmount.value = +res.data.moneyProfit
      }
    }

    const getRealMoneyProfit = async () => {
      const res = await RealMoneyProfitApi.getRealMoneyProfit({ date: props.date })

      if (res?.data) {
        realMoneyProfitData.value = res.data.realMoneyProfit.data
        realMoneyProfitAmount.value = realMoneyProfitData.value.id
          ? realMoneyProfitData.value.attributes.amount
          : moneyProfitAmount.value
      }
    }

    const save = async () => {
      const res = await RealMoneyProfitApi.saveRealMoneyProfit({
        date: props.date,
        amount: realMoneyProfitAmount.value,
        withCashRegisterTransaction: withCashRegisterTransaction.value,
      })

      if (res?.data) {
        realMoneyProfitData.value = res.data.realMoneyProfit.data
        vm.$toast.success('現金利益(実態)を保存しました')
        if (withCashRegisterTransaction.value) vm.$toast.success('レジ出金を更新しました')
      }

      emit('saved')
    }

    watch(() => realMoneyProfitAmount.value, val => {
      if (val < 0) withCashRegisterTransaction.value = false
    })

    initWith([
      getMoneyProfit().then(() => getRealMoneyProfit()),
    ])

    return {
      // data
      isLoading,
      moneyProfitAmount,
      realMoneyProfitAmount,
      realMoneyProfitData,
      withCashRegisterTransaction,

      // methods
      save,
    }
  },
}
</script>
