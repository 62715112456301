<template>
  <div>
    <v-combobox
      v-if="readonly"
      :value="clubEventTagName"
      :items="clubEventTags"
      label="イベント内容"
      item-value="attributes.name"
      item-text="attributes.name"
      :return-object="false"
      :loading="isLoading"
      disabled
      hide-details
      append-icon=""
      readonly
      class="ma-0 pa-0"
    />

    <v-combobox
      v-else
      v-model="clubEventTagName"
      :items="clubEventTags"
      label="イベント内容"
      item-value="attributes.name"
      item-text="attributes.name"
      :return-object="false"
      :loading="isLoading || isSubmitting"
      :disabled="isLoading || isSubmitting"
      :menu-props="{ auto: true, offsetY: true }"
      hide-details
      class="ma-0 pa-0"
      @change="submit"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import DailyClubEventApi from '@/api/admin/DailyClubEvent'
import ClubEventTagApi from '@/api/admin/ClubEventTag'
import useCompInit from '@/views/composable/useCompInit'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isSubmitting = ref(false)
    const dailyClubEvent = ref({})
    const clubEventTagName = ref(null)
    const clubEventTags = ref([])

    const getDailyClubEvent = async () => {
      const res = await DailyClubEventApi.getDailyClubEvent({ date: props.date })

      if (res?.data) {
        dailyClubEvent.value = res.data.dailyClubEvent.data
        clubEventTagName.value = res.data.dailyClubEvent.data.meta.clubEventTagName
      }
    }

    const getClubEventTags = async () => {
      const res = await ClubEventTagApi.getClubEventTags()

      if (res?.data) {
        clubEventTags.value = res.data.clubEventTags.data
      }
    }

    const saveDailyClubEvent = async () => {
      if (props.readonly) return

      isSubmitting.value = true
      const res = await DailyClubEventApi.saveDailyClubEvent({
        date: props.date,
        clubEventTagName: clubEventTagName.value,
      })

      if (res?.data) {
        vm.$toast.success('イベントを保存しました')
        dailyClubEvent.value = res.data.dailyClubEvent.data
        clubEventTagName.value = res.data.dailyClubEvent.data.meta.clubEventTagName
      } else {
        vm.$toast.error('イベントの保存に失敗しました')
      }

      isSubmitting.value = false
    }

    const destroyDailyClubEvent = async () => {
      if (props.readonly) return

      isSubmitting.value = true
      const res = await DailyClubEventApi.destroyDailyClubEvent({ date: props.date })

      if (res?.data) {
        vm.$toast.success('イベントを削除しました')
        dailyClubEvent.value = {}
        clubEventTagName.value = null
      } else {
        vm.$toast.error('イベントの削除に失敗しました')
      }

      isSubmitting.value = false
    }

    const submit = () => {
      if (dailyClubEvent.value.id && isEmpty(clubEventTagName.value)) {
        destroyDailyClubEvent()
      } else {
        saveDailyClubEvent()
      }
    }

    initWith([
      getDailyClubEvent(),
      getClubEventTags(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      dailyClubEvent,
      clubEventTagName,
      clubEventTags,

      // methods
      submit,
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
</style>
