<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <transition name="slideRight">
          <v-row
            v-if="selected.length > 0"
            style="animation-duration: 0.3s"
          >
            <v-col class="px-4 py-6 d-flex">
              <v-spacer />

              <v-btn
                color="primary"
                :ripple="false"
                @click="save"
              >
                {{ toSaveStatus.text }}
              </v-btn>
            </v-col>
          </v-row>
        </transition>

        <v-data-table
          v-model="selected"
          :headers="earningsFromWageHeaders"
          :items="earningsFromWageItems[role.value]"
          :header-props="{ sortByText: 'ソート' }"
          item-key="user.id"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
          no-data-text="評価の結果: 対象者なし"
        >
          <template #[`item.timecard.startAt`]="{item}">
            <v-text-field
              v-for="(timecard, timecardIdx) in item.timecards"
              :key="`user-${item.user.id}-timecard-${timecard.id}-${timecardIdx}-start-at`"
              :value="timecard.startAt ? hhmm(timecard.startAt) : null"
              hide-details
              type="time"
              class="pa-0 ma-0 my-2"
              style="width: 64px"
              :error="new Date(timecard.startAt) > new Date(timecard.leaveAt)"
              @change="changeTime({
                time: $event,
                updateTargetObj: timecard,
                updateTargetKey: 'startAt',
                item: item,
              })"
            />
          </template>

          <template #[`item.timecard.leaveAt`]="{item}">
            <div
              v-for="(timecard, timecardIdx) in item.timecards"
              :key="`user-${item.user.id}-timecard-${timecard.id}-${timecardIdx}-leave-at`"
            >
              <v-text-field
                :value="(timecard.startAt && timecard.leaveAt) ? hhmm(timecard.leaveAt) : null"
                :disabled="!timecard.startAt"
                :error="!timecard.leaveAt"
                hide-details
                type="time"
                class="pa-0 ma-0 my-2"
                style="width: 64px"
                @change="changeTime({
                  time: $event,
                  updateTargetObj: timecard,
                  updateTargetKey: 'leaveAt',
                  item: item,
                })"
              />
            </div>
          </template>

          <template #[`item.timecard.breakTimecardsAttributes.breakTimeStartAt`]="{item}">
            <div
              v-for="(timecard, timecardIdx) in item.timecards"
              :key="`user-${item.user.id}-timecard-${timecard.id}-${timecardIdx}-break-timecard-start-at`"
            >
              <v-text-field
                v-for="(breakTimecard, breakTimecardIdx) in timecard.breakTimecardsAttributes"
                :key="`user-${item.user.id}-break-timecard-${breakTimecard.id}-${breakTimecardIdx}-breakTimeStartAt`"
                :value="(timecard.startAt && breakTimecard.breakTimeStartAt) ? hhmm(breakTimecard.breakTimeStartAt) : null"
                :disabled="!timecard.startAt || !timecard.leaveAt"
                hide-details
                type="time"
                class="pa-0 ma-0 my-2"
                style="width: 64px"
                @input="changeTime({
                  time: $event,
                  updateTargetObj: breakTimecard,
                  updateTargetKey: 'breakTimeStartAt',
                  item: item,
                })"
              />
            </div>
          </template>

          <template #[`item.timecard.breakTimecardsAttributes.breakTimeEndAt`]="{item}">
            <div
              v-for="(timecard, timecardIdx) in item.timecards"
              :key="`user-${item.user.id}-timecard-${timecard.id}-${timecardIdx}-break-timecard-end-at`"
            >
              <v-text-field
                v-for="(breakTimecard, breakTimecardIdx) in timecard.breakTimecardsAttributes"
                :key="`user-${item.user.id}-break-timecard-${breakTimecard.id}-${breakTimecardIdx}-breakTimeEndAt`"
                :value="(timecard.startAt && breakTimecard.breakTimeStartAt) ? hhmm(breakTimecard.breakTimeEndAt) : null"
                :disabled="!timecard.startAt || !timecard.leaveAt || !breakTimecard.breakTimeStartAt"
                hide-details
                type="time"
                class="pa-0 ma-0 my-2"
                style="width: 64px"
                @input="changeTime({
                  time: $event,
                  updateTargetObj: breakTimecard,
                  updateTargetKey: 'breakTimeEndAt',
                  item: item,
                })"
              />
            </div>
          </template>

          <template #[`item.workingHours`]="{item}">
            {{ calcWorkingHours(item.timecards) }}
          </template>

          <template #[`item.earningsFromWage`]="{item}">
            <div
              v-for="(earning, earningIdx) in item.earningsFromWage"
              :key="`user-${item.user.id}-earnings-rom-wage-${earning.id}-${earningIdx}`"
            >
              <v-text-field
                v-model.number="earning.amount"
                :loading="!earning.id"
                :messages="!earning.id ? '未保存/システム提案中' : ''"
                :hide-details="!!earning.id"
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                min="0"
                prefix="¥"
                class="pa-0 ma-0 my-2"
              />
            </div>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  chain,
  map,
  update,
  each,
  flatMap,
  find,
  every,
} from 'lodash'
import {
  addDays,
  intervalToDuration,
  hoursToSeconds,
  minutesToSeconds,
  subSeconds,
} from 'date-fns'
import useCompInit from '@/views/composable/useCompInit'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import TimecardApi from '@/api/admin/Timecard'
import EarningsApi from '@/api/admin/Earnings'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { initWith, isLoading } = useCompInit()
    const { hhmm, dateTime } = useDateFormat()
    const { currentClub } = useCurrentData()
    const currentTabIdx = ref(0)

    const earningsFromWageHeaders = ref([
      { value: 'user.name', text: '対象者' },
      { value: 'timecard.startAt', text: '出勤' },
      { value: 'timecard.leaveAt', text: '退勤' },
      { value: 'timecard.breakTimecardsAttributes.breakTimeStartAt', text: '休憩IN' },
      { value: 'timecard.breakTimecardsAttributes.breakTimeEndAt', text: '休憩OUT' },
      { value: 'workingHours', text: '勤務時間' },
      { value: 'earningsFromWage', text: '支給額' },
    ])
    const castEarningsFromWageItems = ref([])
    const waiterEarningsFromWageItems = ref([])
    const selected = ref([])
    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
    ]

    const earningsFromWageItems = computed(() => {
      return {
        cast: castEarningsFromWageItems.value,
        waiter: waiterEarningsFromWageItems.value,
      }
    })

    const toSaveStatus = computed(() => {
      if (selected.value.length <= 0) return { value: 'disabled', text: '保存できません' }

      if (every(selected.value, o => every(o.earningsFromWage, e => !!e.id))) return { value: 'updateOnly', text: '更新' }
      if (every(selected.value, o => every(o.earningsFromWage, e => !e.id))) return { value: 'createOnly', text: '保存' }

      return { value: 'save', text: '保存/更新' }
    })

    const initEarningsFromWageItems = staffItemsData => {
      return chain(staffItemsData)
        .map(item => {
          return {
            ...item,
            timecards: map(item.timecards, timecard => {
              return {
                id: timecard.id,
                userId: item.user.id,
                startAt: new Date(dateTime(timecard.startAt)),
                leaveAt: timecard.leaveAt ? new Date(dateTime(timecard.leaveAt)) : null,
                breakTimecardsAttributes: [
                  ...(
                    timecard.breakTimecards.length > 0
                      ? map(timecard.breakTimecards, breakTimecard => ({
                        id: breakTimecard.id,
                        breakTimeStartAt: breakTimecard.breakTimeStartAt ? new Date(dateTime(breakTimecard.breakTimeStartAt)) : null,
                        breakTimeEndAt: breakTimecard.breakTimeEndAt ? new Date(dateTime(breakTimecard.breakTimeEndAt)) : null,
                      }))
                      : [{ id: null, breakTimeStartAt: null, breakTimeEndAt: null }] // ここを配列に変更
                  ),
                ],
              }
            }),
          }
        })
        .orderBy(o => o.timecards[0]?.startAt)
        .value()
    }

    const calcWageAmount = async item => {
      const res = await TimecardApi.calcWageAmount({
        userId: item.user.id,
        timecards: item.timecards,
      })

      if (res?.status === 200) {
        update(item.earningsFromWage[0], 'amount', () => res.data)

        // NOTE: intervalも0(日次)でしか送らないのでearningsFromWageは基本的には1つしかない想定だが、has_many :earnings
        // 一応他のやつがあったら0円にする処理
        each(item.earningsFromWage.slice(1, item.earningsFromWage.length), o => update(o, 'amount', 0))
      }
    }

    const changeTime = async ({
      time,
      updateTargetObj,
      updateTargetKey,
      item,
    }) => {
      let newDatetime

      if (!time) {
        update(updateTargetObj, updateTargetKey, () => null)
        calcWageAmount(item)

        return
      }

      newDatetime = new Date(`${props.date.split('-').join('/')} ${time}`)
      const midnightDatetime = new Date(`${props.date.split('-').join('/')} ${currentClub.value.openAt.split('T')[1].slice(0, 5)}`)

      // e.g. 2022/09/01 23:00がstartAtで、endAtに01:00と指定してきた場合2022/09/02にしなければならない
      if (midnightDatetime > newDatetime) newDatetime = addDays(newDatetime, 1)

      update(updateTargetObj, updateTargetKey, () => newDatetime)
      calcWageAmount(item)
    }

    const calcWorkingHours = timecards => {
      const stayingSeconds = chain(timecards)
        .flatMap(timecard => {
          if (!timecard.startAt || !timecard.leaveAt) return []

          return intervalToDuration({ start: timecard.startAt, end: timecard.leaveAt })
        })
        .sumBy(duration => {
          return hoursToSeconds(duration.hours) + minutesToSeconds(duration.minutes)
        })
        .value()

      const breakingSeconds = chain(timecards)
        .flatMap('breakTimecardsAttributes')
        .flatMap(breakTimecard => {
          if (!breakTimecard.breakTimeStartAt || !breakTimecard.breakTimeEndAt) return []

          return intervalToDuration({ start: breakTimecard.breakTimeStartAt, end: breakTimecard.breakTimeEndAt })
        })
        .sumBy(duration => {
          return hoursToSeconds(duration.hours) + minutesToSeconds(duration.minutes)
        })
        .value()

      const workingSeconds = stayingSeconds - breakingSeconds
      if (workingSeconds <= 0) return ''

      const now = new Date()
      const workingDuration = intervalToDuration({ start: subSeconds(now, workingSeconds), end: now })
      const hours = `${((workingDuration.days * 24) + workingDuration.hours)}`.padStart(2, '0')
      const minutes = `${workingDuration.minutes}`.padStart(2, '0')

      return `${hours}:${minutes}`
    }

    const initEarningsFromWage = async () => {
      // NOTE: 現状、日次のインターバルでしかクロージングさせないのでintervalは0
      const res = await TimecardApi.initEarnings({ date: props.date, interval: 0 })

      if (res?.data) {
        castEarningsFromWageItems.value = initEarningsFromWageItems(res.data.castItems)
        waiterEarningsFromWageItems.value = initEarningsFromWageItems(res.data.waiterItems)
      }
    }

    const saveAllTimecards = async timecards => {
      const res = await TimecardApi.saveAll({ timecards })

      if (res?.data) {
        // NOTE: timecardのstartAtが空白のやつは表示から消す
        const items = earningsFromWageItems.value[roles[currentTabIdx.value].value] || []
        const removeIdx = flatMap(items, (item, itemIdx) => {
          return find(item.timecards, timecard => !timecard.startAt) ? itemIdx : []
        })
        for (let i = removeIdx.length - 1; i >= 0; i -= 1) items.splice(removeIdx[i], 1)

        // NOTE: 出勤したやつしかとってきてないのでタイムカードは常に更新
        vm.$toast.success('タイムカードを更新しました')
      }
    }

    const saveAllEarnings = async earnings => {
      const res = await EarningsApi.saveAll({ earnings })

      if (res?.data) {
        vm.$toast.success(`基本給を${toSaveStatus.value.text}しました`)

        // NOTE: すべてのレコードが更新処理でない限り初期化してidを取得する
        if (!(toSaveStatus.value.value === 'updateOnly')) emit('reload')
      }

      selected.value = []
    }

    const save = () => {
      const timecardsToSave = flatMap(selected.value, o => o.timecards)
      if (timecardsToSave.length > 0) saveAllTimecards(timecardsToSave)

      const earningsToSave = flatMap(selected.value, o => o.earningsFromWage)
      if (earningsToSave.length > 0) saveAllEarnings(earningsToSave)
    }

    const timeoutId = setTimeout(() => {
      if (isLoading.value) emit('loading', true)
    }, 3000)
    initWith(
      [
        initEarningsFromWage(),
      ],
    ).then(() => {
      emit('loading', false)
      window.clearTimeout(timeoutId)
    })

    return {
      // data
      isLoading,
      currentTabIdx,
      earningsFromWageHeaders,
      castEarningsFromWageItems,
      waiterEarningsFromWageItems,
      roles,

      // computed
      earningsFromWageItems,
      toSaveStatus,

      // methods
      hhmm,
      changeTime,
      calcWorkingHours,
      save,

      // test
      selected,
    }
  },
}
</script>
