<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div class="closing-earnings">
    <v-card class="daily-report-contents-wrapper mb-6">
      <v-card-title>
        給料
        <v-divider inset />
      </v-card-title>
      <!-- NOTE:  stepperを$vuetify.breakpoint.xsOnlyでモバイルと分けてるので編集時時は要確認-->
      <v-card-text>
        <v-card class="daily-report-contents-wrapper">
          <v-stepper
            v-if="!$vuetify.breakpoint.xsOnly"
            v-model="activeStep"
            flat
            class="transparent custom-header"
            alt-labels
            non-linear
          >
            <v-stepper-header>
              <v-stepper-step step="1" editable>基本給</v-stepper-step><v-divider />
              <v-stepper-step step="2" editable>バック</v-stepper-step><v-divider />
              <v-stepper-step step="3" editable>その他/調整</v-stepper-step><v-divider />
              <v-stepper-step step="4" editable>変動給</v-stepper-step><v-divider />
              <v-stepper-step step="5" editable>総支給/日払</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                step="1"
                class="pt-0"
              >
                <v-lazy
                  :value="isActive.earningsFromWage && activeStep === 1"
                  min-height="150"
                  min-width="100%"
                  transition="fade-transition"
                >
                  <earnings-from-wage
                    :key="`earnings-from-wage-${reloadCount.earningsFromWage}`"
                    :date="date"
                    @loading="isLoading = $event"
                    @reload="reloadCount.earningsFromWage += 1"
                  />
                </v-lazy>
              </v-stepper-content>

              <v-stepper-content
                step="2"
                class="pt-0"
              >
                <v-lazy
                  :value="isActive.earningsFromRebateSystem && activeStep === 2"
                  min-height="150"
                  min-width="100%"
                  transition="fade-transition"
                >
                  <earnings-from-rebate-system
                    :key="`earnings-from-rebate-system-${reloadCount.earningsFromRebateSystem}`"
                    :date="date"
                    :fixed-at="fixedAt"
                    @loading="isLoading = $event"
                    @reload="reloadCount.earningsFromRebateSystem += 1"
                  />
                </v-lazy>
              </v-stepper-content>

              <v-stepper-content
                step="3"
                class="pt-0"
              >
                <v-lazy
                  :value="isActive.earningsOrphans && activeStep === 3"
                  min-height="150"
                  min-width="100%"
                  transition="fade-transition"
                >
                  <earnings-orphans
                    :key="`earnings-orphans-${reloadCount.earningsOrphans}`"
                    :date="date"
                    :users="users"
                    :fixed-at="fixedAt"
                    @loading="isLoading = $event"
                    @reload="reloadCount.earningsOrphans += 1"
                  />
                </v-lazy>
              </v-stepper-content>

              <v-stepper-content
                step="4"
                class="pt-0"
              >
                <v-lazy
                  :value="isActive.earningsFromPaySystem && activeStep === 4"
                  min-height="150"
                  min-width="100%"
                  transition="fade-transition"
                >
                  <earnings-from-pay-system
                    :key="`earnings-from-pay-system-${activeStep}-${reloadCount.earningsFromPaySystem}`"
                    :date="date"
                    :fixed-at="fixedAt"
                    @loading="isLoading = $event"
                    @reload="reloadCount.earningsFromPaySystem += 1"
                  />
                </v-lazy>
              </v-stepper-content>

              <v-stepper-content
                step="5"
                class="pt-0"
              >
                <v-lazy
                  :value="isActive.earningsClosing && activeStep === 5"
                  min-height="150"
                  min-width="100%"
                  transition="fade-transition"
                >
                  <earnings-closing
                    :key="`earnings-closing-${activeStep}-${reloadCount.earningsClosing}`"
                    :date="date"
                    :fixed-at="fixedAt"
                    @loading="isLoading = $event"
                    @reload="reloadCount.earningsClosing += 1"
                  />
                </v-lazy>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-stepper
            v-else
            vertical
            non-linear
            class="custom-header"
          >
            <v-stepper-step step="1" editable>基本給</v-stepper-step>
            <v-stepper-content
              step="1"
              class="pt-0"
            >
              <v-lazy
                :value="isActive.earningsFromWage && activeStep === 1"
                min-height="150"
                min-width="100%"
                transition="fade-transition"
              >
                <earnings-from-wage
                  :key="`earnings-from-wage-${reloadCount.earningsFromWage}`"
                  :date="date"
                  @loading="isLoading = $event"
                  @reload="reloadCount.earningsFromWage += 1"
                />
              </v-lazy>
            </v-stepper-content>

            <v-stepper-step step="2" editable>バック</v-stepper-step>
            <v-stepper-content
              step="2"
              class="pt-0"
            >
              <v-lazy
                :value="isActive.earningsFromRebateSystem && activeStep === 2"
                min-height="150"
                min-width="100%"
                transition="fade-transition"
              >
                <earnings-from-rebate-system
                  :key="`earnings-from-rebate-system-${reloadCount.earningsFromRebateSystem}`"
                  :date="date"
                  :fixed-at="fixedAt"
                  @loading="isLoading = $event"
                  @reload="reloadCount.earningsFromRebateSystem += 1"
                />
              </v-lazy>
            </v-stepper-content>

            <v-stepper-step step="3" editable>その他/調整</v-stepper-step>
            <v-stepper-content
              step="3"
              class="pt-0"
            >
              <v-lazy
                :value="isActive.earningsOrphans && activeStep === 3"
                min-height="150"
                min-width="100%"
                transition="fade-transition"
              >
                <earnings-orphans
                  :key="`earnings-orphans-${reloadCount.earningsOrphans}`"
                  :date="date"
                  :users="users"
                  :fixed-at="fixedAt"
                  @loading="isLoading = $event"
                  @reload="reloadCount.earningsOrphans += 1"
                />
              </v-lazy>
            </v-stepper-content>

            <v-stepper-step step="4" editable>変動給</v-stepper-step>
            <v-stepper-content
              step="4"
              class="pt-0"
            >
              <v-lazy
                :value="isActive.earningsFromPaySystem && activeStep === 4"
                min-height="150"
                min-width="100%"
                transition="fade-transition"
              >
                <earnings-from-pay-system
                  :key="`earnings-from-pay-system-${activeStep}-${reloadCount.earningsFromPaySystem}`"
                  :date="date"
                  :fixed-at="fixedAt"
                  @loading="isLoading = $event"
                  @reload="reloadCount.earningsFromPaySystem += 1"
                />
              </v-lazy>
            </v-stepper-content>

            <v-stepper-step step="5" editable>総支給/日払</v-stepper-step>
            <v-stepper-content
              step="5"
              class="pt-0"
            >
              <v-lazy
                :value="isActive.earningsClosing && activeStep === 5"
                min-height="150"
                min-width="100%"
                transition="fade-transition"
              >
                <earnings-closing
                  :key="`earnings-closing-${activeStep}-${reloadCount.earningsClosing}`"
                  :date="date"
                  :fixed-at="fixedAt"
                  @loading="isLoading = $event"
                  @reload="reloadCount.earningsClosing += 1"
                />
              </v-lazy>
            </v-stepper-content>
          </v-stepper>

          <v-snackbar
            :value="isLoading"
            centered
            :timeout="-1"
            transition="fade-transition"
            content-class="d-flex justify-center"
          >
            <transition name="fade">
              <span :key="loadingMessages[loadingMessageIdx]">
                {{ loadingMessages[loadingMessageIdx] }}
              </span>
            </transition>
          </v-snackbar>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import EarningsFromWage from '@/views/components/daily_report/Closing/EarningsFromWage.vue'
import EarningsFromRebateSystem from '@/views/components/daily_report/Closing/EarningsFromRebateSystem.vue'
import EarningsFromPaySystem from '@/views/components/daily_report/Closing/EarningsFromPaySystem.vue'
import EarningsOrphans from '@/views/components/daily_report/Closing/EarningsOrphans.vue'
import EarningsClosing from '@/views/components/daily_report/Closing/EarningsClosing.vue'

export default {
  components: {
    EarningsFromWage,
    EarningsFromRebateSystem,
    EarningsFromPaySystem,
    EarningsOrphans,
    EarningsClosing,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup() {
    const isActive = reactive({
      earningsFromWage: false,
      earningsFromRebateSystem: false,
      earningsFromPaySystem: false,
      earningsOrphans: false,
      earningsClosing: false,
    })
    const reloadCount = reactive({
      earningsFromWage: 0,
      earningsFromRebateSystem: 0,
      earningsFromPaySystem: 0,
      earningsOrphans: 0,
      earningsClosing: 0,
    })
    const activeStep = ref(1)
    const isLoading = ref(false)
    const loadingMessageIdx = ref(0)
    const loadingMessages = [
      '計算中...',
      'もう少しです!',
      'あと少し...',
      '計算には1~2分かかることがあります',
      'あと一歩...',
      'あともうわずか...',
      'ほんのちょっと...',
      '計算が長すぎる場合はサポートまでお問い合わせください',
    ]
    const loadingMessageInterval = ref()

    const showLoadingMessage = () => {
      return setInterval(
        () => {
          loadingMessageIdx.value += 1
          if (loadingMessageIdx.value >= loadingMessages.length) loadingMessageIdx.value = 0
        },
        10000,
      )
    }

    watch(() => isLoading.value, (newBool, prevBool) => {
      if (!prevBool && newBool) loadingMessageInterval.value = showLoadingMessage()
      if (prevBool && !newBool && loadingMessageInterval.value) clearInterval(loadingMessageInterval.value)
    })

    return {
      // data
      isActive,
      reloadCount,
      isLoading,
      loadingMessageIdx,
      loadingMessages,
      activeStep,
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
</style>

<style lang="scss">
.closing-earnings th {
  min-width: 120px;
}
</style>
