<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      style="background: transparent"
    >
      <v-tab
        v-for="role in roles"
        :key="`tab[${role.value}]`"
      >
        {{ role.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`tabItems[${role.value}]`"
      >
        <transition name="slideRight">
          <v-row
            v-if="savable"
            style="animation-duration: 0.3s"
          >
            <v-col class="px-4 pt-6 pb-0 d-flex">
              <v-spacer />

              <v-btn
                color="primary"
                :ripple="false"
                @click="save"
              >
                {{ toSaveStatus.text }}
              </v-btn>
            </v-col>
          </v-row>
        </transition>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <user-select
                :key="`user-select-${role.value}-${items[role.value].length}`"
                :users="usersByRole[role.value]"
                :with-query="false"
                :clearable="false"
                :prepend-inner-icon="icons.mdiAccountPlusOutline"
                label="対象者を追加"
                @selected="addUser($event)"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-combobox
                v-model="columnName"
                :items="persistedEarningTexts[role.value]"
                hide-details
                single-line
                label="任意の項目"
                :menu-props="{ auto: true, offsetY: true }"
              >
                <template #append-outer>
                  <v-btn
                    small
                    :ripple="false"
                    :disabled="!columnName"
                    color="primary"
                    @click="addEarnings"
                  >
                    追加
                  </v-btn>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-data-table
          :key="`${role.value}-data-table-${editCount}`"
          v-model="selected"
          :headers="[
            { value: 'user.name', text: '対象者' },
            ...headers[role.value],
            { value: 'earningsTotalAmount', text: '支給額' },
          ]"
          :items="items[role.value]"
          :header-props="{ sortByText: 'ソート' }"
          item-key="user.id"
          class="text-no-wrap"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
          no-data-text="データがありません"
        >
          <template
            v-for="header in headers[role.value]"
            #[`item.${header.value}`]="{item}"
          >
            <v-text-field
              v-for="(earning, earningIdx) in item.earnings.filter(o => o.text === header.text)"
              :key="`user-${item.user.id}-earnings-from-pay-system-unit-${header.text}-${earningIdx}`"
              v-model.number="earning.amount"
              :loading="!earning.id"
              :messages="!earning.id ? '未保存' : ''"
              :hide-details="!!earning.id"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              min="0"
              prefix="¥"
              class="pa-0 ma-0 my-2"
            />
          </template>

          <template #[`item.earningsTotalAmount`]="{item}">
            <v-text-field
              :value="sumTotalEarningsAmount(item)"
              readonly
              solo
              flat
              prefix="¥"
              hide-details
              class="pa-0 ma-0 my-2"
            />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  sumBy,
  each,
  groupBy,
  chain,
  map,
  find,
  flatMap,
  every,
  reject,
  includes,
} from 'lodash'
import { mdiAccountPlusOutline } from '@mdi/js'
import EarningsApi from '@/api/admin/Earnings'
import useCompInit from '@/views/composable/useCompInit'
import UserSelect from '@/views/components/util/filter/UserSelect.vue'

export default {
  components: {
    UserSelect,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { initWith, isLoading } = useCompInit()
    const currentTabIdx = ref(0)
    const castHeaders = ref([])
    const waiterHeaders = ref([])
    const allianceHeaders = ref([])
    const castEarningsItems = ref([])
    const waiterEarningsItems = ref([])
    const allianceEarningsItems = ref([])
    const columnName = ref('')
    const editCount = ref(0)
    const selected = ref([])
    const roles = [
      { text: 'キャスト', value: 'cast' },
      { text: 'ボーイ', value: 'waiter' },
      { text: 'アライアンス', value: 'alliance' },
    ]

    const items = computed(() => {
      return {
        cast: castEarningsItems.value,
        waiter: waiterEarningsItems.value,
        alliance: allianceEarningsItems.value,
      }
    })

    const headers = computed(() => {
      return {
        cast: castHeaders.value,
        waiter: waiterHeaders.value,
        alliance: allianceHeaders.value,
      }
    })

    const usersUnselected = computed(() => {
      const itemUserIds = map(
        [
          ...castEarningsItems.value,
          ...waiterEarningsItems.value,
          ...allianceEarningsItems.value,
        ],
        item => +item.user.id,
      )

      return reject(props.users, user => includes(itemUserIds, +user.id))
    })

    const usersByRole = computed(() => {
      return groupBy(usersUnselected.value, 'attributes.role')
    })

    const persistedEarningTexts = computed(() => {
      // eslint-disable-next-line newline-per-chained-call
      const getTexts = array => { return chain(array).flatMap('earnings').map('text').uniqBy('text').value() }

      return {
        cast: getTexts(castEarningsItems.value),
        waiter: getTexts(waiterEarningsItems.value),
        alliance: getTexts(allianceEarningsItems.value),
      }
    })

    const savable = computed(() => {
      return selected.value.length > 0 && every(selected.value, o => o.earnings.length > 0)
    })

    const toSaveStatus = computed(() => {
      if (!savable.value) return { value: 'disabled', text: '保存できません' }

      if (every(selected.value, o => every(o.earnings, e => !!e.id))) return { value: 'updateOnly', text: '更新' }
      if (every(selected.value, o => every(o.earnings, e => !e.id))) return { value: 'createOnly', text: '保存' }

      return { value: 'save', text: '追加/更新' }
    })

    const sumTotalEarningsAmount = item => {
      // eslint-disable-next-line newline-per-chained-call
      return chain(item.earnings).map('amount').compact().sumBy(o => +o).value()
    }

    const addEarnings = () => {
      const role = roles[currentTabIdx.value].value
      if (!role) return

      const text = columnName.value
      columnName.value = ''

      if (!includes(persistedEarningTexts.value[roles[currentTabIdx.value].value], text)) {
        headers.value[role].splice(0, 0, { text, value: text, width: '120px' })
      }

      each(items.value[role], item => {
        if (find(item.earnings, o => o.text === text)) return

        item.earnings.splice(0, 0, {
          text,
          amount: 0,
          userId: +item.user.id,
          fixedAt: props.fixedAt,
        })
      })

      editCount.value += 1
    }

    const addUser = userId => {
      const role = roles[currentTabIdx.value].value
      if (!role) return

      const userRawData = find(props.users, user => +user.id === +userId)

      // NOTE: initEarningsで送られてくるitemの中のuserはシリアライザー使ってないのでattributesとか持ってない
      const userData = { id: userRawData, ...userRawData.attributes }

      const targetItems = items.value[role]
      const earningsToInit = chain(targetItems)
        .flatMap(o => {
          return map(o.earnings, earning => {
            return {
              id: null,
              text: earning.text,
              amount: 0,
              userId: +userId,
              fixedAt: props.fixedAt,
            }
          })
        })
        .uniqBy('text')
        .value()
      targetItems.splice(0, 0, { user: userData, earnings: earningsToInit })

      editCount.value += 1
    }

    const getOrphans = async () => {
      // NOTE: 現状、日次のインターバルでしかクロージングさせないのでintervalは0
      const res = await EarningsApi.getOrphans({ date: props.date, interval: 0 })

      if (res?.data) {
        castEarningsItems.value = [...res.data.castItems]
        waiterEarningsItems.value = [...res.data.waiterItems]
        allianceEarningsItems.value = [...res.data.allianceItems]

        castHeaders.value = [...res.data.castHeaders]
        waiterHeaders.value = [...res.data.waiterHeaders]
        allianceHeaders.value = [...res.data.allianceHeaders]
      }
    }

    const saveAllEarnings = async earnings => {
      const res = await EarningsApi.saveAll({ earnings })

      if (res?.data) {
        vm.$toast.success(`${toSaveStatus.value.text}しました`)

        // NOTE: すべてのレコードが更新処理でない限り初期化してidを取得する
        if (!(toSaveStatus.value.value === 'updateOnly')) emit('reload')
      }

      selected.value = []
    }

    const save = async () => {
      const earningsToSave = flatMap(selected.value, o => o.earnings)
      if (earningsToSave.length > 0) await saveAllEarnings(earningsToSave)
    }

    const timeoutId = setTimeout(() => {
      if (isLoading.value) emit('loading', true)
    }, 3000)
    initWith(
      [
        getOrphans(),
      ],
    ).then(() => {
      emit('loading', false)
      clearTimeout(timeoutId)
    })

    return {
      // data
      isLoading,
      currentTabIdx,
      castHeaders,
      waiterHeaders,
      allianceHeaders,
      castEarningsItems,
      waiterEarningsItems,
      allianceEarningsItems,
      selected,
      roles,
      columnName,
      editCount,

      // computed
      items,
      headers,
      usersByRole,
      savable,
      toSaveStatus,
      persistedEarningTexts,

      // methods
      sumTotalEarningsAmount,
      addEarnings,
      addUser,
      save,

      icons: {
        mdiAccountPlusOutline,
      },
    }
  },
}
</script>
