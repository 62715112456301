<template>
  <v-card class="daily-report-contents-wrapper">
    <v-card-title>
      レジ
      <v-divider inset />

      <v-switch
        v-model="showAll"
        label="すべてのTxを確認"
        hide-details
        class="pa-0 ma-0"
      />
    </v-card-title>

    <v-card-text v-if="showAll">
      <cash-register-transactions
        :show-date-picker="false"
      />
    </v-card-text>

    <v-card-text>
      <v-card class="daily-report-contents-wrapper mb-4">
        <v-card-title>
          現金 諸出
        </v-card-title>
        <v-card-text>
          <v-lazy
            :value="isActive.cashExpenses"
            min-height="150"
            min-width="100%"
            transition="fade-transition"
          >
            <cash-expenses
              :key="`cash-expenses-${date}`"
              :date="date"
              :expense-on="fixedAt"
              @saved="save"
            />
          </v-lazy>
        </v-card-text>
      </v-card>

      <v-card class="daily-report-contents-wrapper mb-4">
        <v-card-title>
          現金 諸入
        </v-card-title>
        <v-card-text>
          <v-lazy
            :value="isActive.cashBenefits"
            min-height="150"
            min-width="100%"
            transition="fade-transition"
          >
            <cash-benefits
              :key="`cash-benefits-${date}`"
              :date="date"
              :benefit-on="fixedAt"
              @saved="save"
            />
          </v-lazy>
        </v-card-text>
      </v-card>

      <v-card class="daily-report-contents-wrapper">
        <v-card-title>
          現金 利益
        </v-card-title>

        <v-card-text>
          <v-lazy
            :value="isActive.withdrawMoneyProfit"
            min-height="150"
            min-width="100%"
            transition="fade-transition"
          >
            <real-money-profit
              :key="`withdraw-money-profit-${date}-${savedCount}`"
              :date="date"
              @saved="save"
            />
          </v-lazy>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import CashRegisterTransactions from '@/views/waiter/CashRegisterTransactions.vue'
import CashExpenses from '@/views/components/daily_report/Closing/CashExpenses.vue'
import CashBenefits from '@/views/components/daily_report/Closing/CashBenefits.vue'
import RealMoneyProfit from '@/views/components/daily_report/Closing/RealMoneyProfit.vue'

export default {
  components: {
    CashExpenses,
    CashBenefits,
    CashRegisterTransactions,
    RealMoneyProfit,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup() {
    const isActive = reactive({
      cashExpenses: false,
      cashBenefits: false,
      withdrawMoneyProfit: false,
    })
    const showAll = ref(false)
    const savedCount = ref(0)

    const save = () => {
      showAll.value = false
      savedCount.value += 1
    }

    return {
      // data
      isActive,
      showAll,
      savedCount,

      // methods
      save,
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.closing-earnings th {
  min-width: 120px;
}
</style>
