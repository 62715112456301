var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-tabs',{staticStyle:{"background":"transparent"},attrs:{"show-arrows":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:("tab[" + (role.value) + "]")},[_vm._v(" "+_vm._s(role.text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab-item',{key:("tabItems[" + (role.value) + "]")},[_c('transition',{attrs:{"name":"slideRight"}},[(_vm.selected.length > 0)?_c('v-row',{staticStyle:{"animation-duration":"0.3s"}},[_c('v-col',{staticClass:"px-4 py-6 d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","ripple":false},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.toSaveStatus.text)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":[
          { value: 'user.name', text: '対象者' } ].concat( _vm.headers[role.value],
          [{ value: 'earningsTotalAmount', text: '支給額' }] ),"items":_vm.items[role.value],"header-props":{ sortByText: 'ソート' },"item-key":"user.id","hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":"","no-data-text":"評価の結果: 対象者なし"},scopedSlots:_vm._u([_vm._l((_vm.headers[role.value]),function(header){return {key:("header." + (header.value)),fn:function(ref){
        var headerObj = ref.header;
return [_vm._v(" "+_vm._s(headerObj.text)+" "),(headerObj.evaluationCycleSpan)?_c('v-chip',{key:("header-" + (header.value)),staticClass:"v-chip-light-bg",class:((_vm.evaluationCycleSpanColors[headerObj.evaluationCycleSpan]) + "--text"),attrs:{"color":_vm.evaluationCycleSpanColors[headerObj.evaluationCycleSpan],"small":""}},[_vm._v(" "+_vm._s(_vm.evaluationCycleSpanTexts[headerObj.evaluationCycleSpan])+" ")]):_vm._e()]}}}),_vm._l((_vm.headers[role.value]),function(header){return {key:("item." + (header.value)),fn:function(ref){
        var item = ref.item;
return [(item.earnings.filter(function (o) { return +o.rebateSystemUnitId === +header.rebateSystemUnitId; }).length > 0)?_vm._l((item.earnings.filter(function (o) { return +o.rebateSystemUnitId === +header.rebateSystemUnitId; })),function(earning,earningIdx){return _c('v-text-field',{key:("user-" + (item.user.id) + "-earnings-from-rebate-system-unit-" + (header.rebateSystemUnitId) + "-" + earningIdx),staticClass:"pa-0 ma-0 my-2",attrs:{"loading":!earning.id,"hide-details":!!earning.id,"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","prefix":"¥"},model:{value:(earning.amount),callback:function ($$v) {_vm.$set(earning, "amount", _vm._n($$v))},expression:"earning.amount"}})}):[_c('v-text-field',{key:("user-arbitrary-earnings-from-rebate-system-unit-" + (header.rebateSystemUnitId)),staticClass:"pa-0 ma-0 my-2",attrs:{"hide-details":""},on:{"click":function($event){return _vm.addArbitraryEarning(item, header)}}})]]}}}),{key:"item.earningsTotalAmount",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",attrs:{"value":_vm.sumTotalEarningsAmount(item),"readonly":"","solo":"","flat":"","prefix":"¥","hide-details":""}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }