var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',{staticClass:"closing-cash-benefits"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":[
      { value: 'primaryLabel', text: '大ラベル', sortable: false },
      { value: 'secondaryLabel', text: '小ラベル', sortable: false },
      { value: 'amount', text: '金額' } ],"items":_vm.items,"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.primaryLabel",fn:function(ref){
    var item = ref.item;
return [_c('v-combobox',{staticClass:"pa-0 ma-0 my-2",attrs:{"items":_vm.primaryLabels,"item-value":"id","item-text":"name","error":!item.benefitPrimaryLabelId,"single-line":"","hide-details":"","return-object":false,"menu-props":{ auto: true, offsetY: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var idOrName = ref.item;
return [(_vm.primaryLabels.find(function (label) { return +label.id === +idOrName; }))?_c('span',[_vm._v(" "+_vm._s(_vm.primaryLabels.find(function (label) { return +label.id === +idOrName; }).name)+" ")]):_c('span',[_vm._v(" "+_vm._s(idOrName)+" ")])]}}],null,true),model:{value:(item.benefitPrimaryLabelId),callback:function ($$v) {_vm.$set(item, "benefitPrimaryLabelId", $$v)},expression:"item.benefitPrimaryLabelId"}})]}},{key:"item.secondaryLabel",fn:function(ref){
    var item = ref.item;
return [_c('v-combobox',{staticClass:"pa-0 ma-0 my-2",attrs:{"items":_vm.secondaryLabels,"item-value":"id","item-text":"name","single-line":"","hide-details":"","return-object":false,"menu-props":{ auto: true, offsetY: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var idOrName = ref.item;
return [(_vm.secondaryLabels.find(function (label) { return +label.id === +idOrName; }))?_c('span',[_vm._v(" "+_vm._s(_vm.secondaryLabels.find(function (label) { return +label.id === +idOrName; }).name)+" ")]):_c('span',[_vm._v(" "+_vm._s(idOrName)+" ")])]}}],null,true),model:{value:(item.benefitSecondaryLabelId),callback:function ($$v) {_vm.$set(item, "benefitSecondaryLabelId", $$v)},expression:"item.benefitSecondaryLabelId"}})]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('v-text-field',{staticClass:"pa-0 ma-0 my-2",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"0","prefix":"¥","hide-details":""},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})]}}],null,true)}),_c('v-row',{staticClass:"mt-1 position-relative"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"ripple":false,"fab":"","color":"primary","small":"","top":"","absolute":"","left":""},on:{"click":function($event){_vm.items.splice(_vm.items.length, 0, _vm.initItem(null, {}))}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1),(_vm.toSaveStatus.value !== 'disabled')?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-spacer'),_c('transition',{attrs:{"name":"slideRight"}},[(_vm.hasChange)?_c('v-btn',{staticStyle:{"animation-duration":"0.3s"},attrs:{"ripple":false,"color":"primary","disabled":!_vm.isValid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.toSaveStatus.text)+" ")]):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }